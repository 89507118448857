import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import sampleAppTopImage from '../../styles/images/top_icon.png'
import appBackgroundImage from '../../styles/images/trivia_background.png'
import {base} from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import {Modal} from 'reactstrap';
import {PhotoshopPicker} from 'react-color';
import RichTextMarkdown from "../utils/RichTextMarkdown";

class SetUpBrandingVariables extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            tenantVariables: {},
            showTabOne: true,
            showTabTwo: false,
            showTabThree: false,
            loading:true,
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            colorsTab: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidMount() {
      this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
        context: this,
        then(data){
            this.setState({
                tenantVariables: data
            })
        }
      });
      this.setState({
        loading: false
      })
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    handleSubmit(event) {
        event.preventDefault();
        let variables = this.state.tenantVariables || {};
        const fieldsToUpdate = [
            'holdingBody', 'playingBody', 'winningMessage', 'losingMessage',
            'aboveFormText', 'aboveSubmitButtonText', 'belowSubmitButtonText'
        ];

        fieldsToUpdate.forEach(field => {
            let content = variables[field] || "";
            if (content === "<p><br></p>") {
                content = "";
            }
            variables[field] = content;
        });
        let vm = this;
        base.post("tenantVariables/", {
            data: variables,
            then(err){
              vm.setState({loading:false})
              if(!err){
                swal({
                    title: 'Tenant Variables Updated!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                  })
              } else {
                swal({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in',
                    type: 'error',
                    confirmButtonText: 'Ok'
                  })
                console.log(err)
              }
            }
        })
    }

    handleChange(evt){
      let tenantVariablesCopy = this.state.tenantVariables;
      tenantVariablesCopy[evt.target.name] = evt.target.value
      this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    handleRichTextChange = (name, text) => {
        let tenantVariablesCopy = this.state.tenantVariables;
        tenantVariablesCopy[name] = text;
        this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    handleImageChange(event){
      const name_of_file = event.target.name;
      const target = this[name_of_file];
      const file_to_update =target.files[0];
      this.setState({loading:true})
      const vm = this;
      this.UploadImage.upload_file(file_to_update).then(res => {
          vm.setState({loading:false})
          if(res.error){
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          } else if(res.imageUrl) {
              let tenantVariablesCopy = vm.state.tenantVariables;
              tenantVariablesCopy[name_of_file] = res.imageUrl
              vm.setState({
                  tenantVariables: tenantVariablesCopy,
              }, () => {
                  vm[name_of_file].value = ""
              });
          } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: "Something went wrong, please re-upload your image and try again!",
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          }
      })
    }

    switchTextShowing(element){
      if(element === "first"){
        document.getElementById('showfirst').classList.add('active');
        document.getElementById('showthird').classList.remove('active');
        this.setState({
          showTabOne: true,
          showTabTwo: false,
          showTabThree: false,
        })
      }else if(element === "second"){
        document.getElementById('showfirst').classList.remove('active');
        document.getElementById('showthird').classList.remove('active');
        this.setState({
          showTabOne: false,
          showTabTwo: true,
          showTabThree: false,
        })
      } else {
        document.getElementById('showfirst').classList.remove('active');
        document.getElementById('showthird').classList.add('active');
        this.setState({
          showTabOne: false,
          showTabTwo: false,
          showTabThree: true,
        })
      }
    }

    openColorPicker(colorToChange){
        let oldColor = this.state.tenantVariables[colorToChange] || '#fff';
        this.setState({
            colorPickerShow: true,
            colorToShow: oldColor,
            colorToUpdate: colorToChange
        })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        let colorToChange = this.state.colorToUpdate;
        let tenantVariables = this.state.tenantVariables;
        tenantVariables[colorToChange] = this.state.colorToShow;
        this.setState({
            tenantVariables: tenantVariables,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        this.setState({
            colorPickerShow: false,
        })
    }

    toggleColors(){
        if(this.state.colorsTab){
            document.getElementById('showColors').classList.remove('active');
            document.getElementById('showImages').classList.add('active');
        } else {
            document.getElementById('showColors').classList.add('active');
            document.getElementById('showImages').classList.remove('active');
        }
        this.setState({colorsTab: !this.state.colorsTab})
    }

    render() {
        const tenantVariables = this.state.tenantVariables || {};
        const topHeaderImage = tenantVariables.topHeaderImage;
        const backgroundImage = tenantVariables.backgroundImage;
        const frontImage = tenantVariables.frontLogoImage;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0, marginTop:'5px'}}>Game Branding</p>
                <p className="admin-subheader-text">This is where you can edit the game branding on your activation</p>
                <div className="container-out">
                  <div className="admin-form-box" style={{marginTop:'0px', paddingTop:'5px'}}>
                    <form onSubmit={this.handleSubmit} id="create-game-form">
                      <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                      <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                          <li className="nav-item" onClick={()=> this.switchTextShowing('first')}>
                              <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showfirst">
                                  <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Fan Color/Images</span>
                              </a>
                          </li>
                          <li className="nav-item" onClick={()=> this.switchTextShowing('third')}>
                              <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showthird">
                                  <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Game Text</span>
                              </a>
                          </li>
                      </ul>
                    <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{display: this.state.showTabOne === true ? '' : 'none'}}>
                        <li className="nav-item" onClick={()=> this.toggleColors()}>
                            <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showColors">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">Colors</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={()=> this.toggleColors()}>
                            <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showImages">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">Images</span>
                            </a>
                        </li>
                    </ul>
                      <div style={{display: this.state.showTabOne ? 'block' : 'none'}}>
                          <div style={{display: this.state.colorsTab ? 'block': 'none'}}>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>Button Colors</h4>
                                    <div className="form-inline">
                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("primaryColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.primaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="primaryColor" style={{marginRight: 10}}>Button Color</label>
                                        <input id="primaryColor" name="primaryColor" type="text" className="form-control" value={this.state.tenantVariables.primaryColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                        <div style={{height:'10px', width:'100%'}}/>

                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("secondaryColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.secondaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="secondaryColor" style={{marginRight: 10}}>Button Text Color</label>
                                        <input id="secondaryColor" name="secondaryColor" type="text" className="form-control" value={this.state.tenantVariables.secondaryColor} onChange={this.handleChange} placeholder="#000"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4>Logout Color</h4>
                                    <div className="form-inline">
                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("logOutButtonColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.logOutButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="logOutButtonColor" style={{marginRight: 10}}>Button Color</label>
                                        <input id="logOutButtonColor" name="logOutButtonColor" type="text" className="form-control" value={this.state.tenantVariables.logOutButtonColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                    </div>
                                </div>
                            </div>
                            <div style={{height:'10px', width:'100%'}}/>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>Puzzle Colors</h4>
                                    <div className="form-inline">
                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("puzzleBackgroundColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.puzzleBackgroundColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="puzzleBackgroundColor" style={{marginRight: 10}}>Background</label>
                                        <input id="puzzleBackgroundColor" name="puzzleBackgroundColor" type="text" className="form-control" value={this.state.tenantVariables.puzzleBackgroundColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                        <div style={{height:'10px', width:'100%'}}/>

                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("easyModeBorderColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.easyModeBorderColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="easyModeBorderColor" style={{marginRight: 10}}>Easy Mode Border Color</label>
                                        <input id="easyModeBorderColor" name="easyModeBorderColor" type="text" className="form-control" value={this.state.tenantVariables.easyModeBorderColor} onChange={this.handleChange} placeholder="#000"/>

                                    </div>
                                </div>
                            </div>
                          </div>
                          <div style={{display: this.state.colorsTab ? 'none' : 'block'}}>
                            <div className="admin-grid-container three-columns" style={{float:'left', display: 'flex', alignItems: 'flex-end'}}>
                              <div className="form-group" style={{textAlign:'center', margin:20}}>
                                <img src={topHeaderImage} width="160" height="auto" alt=""/>
                                <br/>
                                <label htmlFor="topHeaderImage">Header Image</label>
                                <div className="form-group">
                                  <input style={{display:'none'}} id="topHeaderImage" name="topHeaderImage" type="file" ref={input => {this.topHeaderImage = input; }} onChange={this.handleImageChange}/>
                                  <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topHeaderImage').click()} />
                                </div>
                              </div>
                                <div className="form-group" style={{textAlign:'center', margin:20}}>
                                    <img src={backgroundImage} width="160" height="auto" alt=""/>
                                    <br/>
                                    <label htmlFor="backgroundImage">Phone Background Image</label>
                                    <div className="form-group">
                                        <input style={{display:'none'}} id="backgroundImage" name="backgroundImage" type="file" ref={input => {this.backgroundImage = input; }} onChange={this.handleImageChange}/>
                                        <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('backgroundImage').click()} />
                                    </div>
                                </div>
                                <div className="form-group" style={{textAlign:'center', margin:20}}>
                                    <img src={frontImage} width="150" height="auto" alt=""/>
                                    <br/>
                                    <label htmlFor="frontLogoImage">Front Logo</label>
                                    <div className="form-group">
                                        <input style={{display:'none'}} id="frontLogoImage" name="frontLogoImage" type="file" ref={input => {this.frontLogoImage = input; }} onChange={this.handleImageChange}/>
                                        <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('frontLogoImage').click()} />
                                    </div>
                                </div>
                            </div>
                          </div>
                      </div>
                      <div style={{display: this.state.showTabThree ? 'block' : 'none'}}>
                          <div className="form-group">
                              <label htmlFor="pageTitle">Page Title:</label>
                              <input id="pageTitle" name="pageTitle" type="text" className="form-control" value={this.state.tenantVariables.pageTitle} onChange={this.handleChange} placeholder="Photo Scramble"/>
                          </div>
                          <div className="form-group">
                              <label htmlFor="winningHeader">Prize Won Header Text:</label>
                              <input id="winningHeader" name="winningHeader" type="text" className="form-control" value={this.state.tenantVariables.winningHeader} onChange={this.handleChange} placeholder="You Solved The Puzzle!"/>
                          </div>
                          <div className="form-group">
                              <label htmlFor="winningBody">Prize Won Body Text:</label>
                              <RichTextMarkdown
                                  field={{
                                      id: "winningBody",
                                      name: "winningBody",
                                      value: tenantVariables.winningBody || ""
                                  }}
                                  form={{
                                      setFieldValue: (field, value) => this.handleRichTextChange('winningBody', value)
                                  }}
                                  placeholder=""
                              />
                          </div>
                          <div className="form-group">
                              <label htmlFor="winningNoPrizeHeader">Completed No Prize Header Text:</label>
                              <input id="winningNoPrizeHeader" name="winningNoPrizeHeader" type="text" className="form-control" value={this.state.tenantVariables.winningNoPrizeHeader} onChange={this.handleChange} placeholder="You Solved The Puzzle!"/>
                          </div>
                          <div className="form-group">
                              <label htmlFor="winningNoPrizeBody">Completed No Prize Body Text:</label>
                              <RichTextMarkdown
                                  field={{
                                      id: "winningNoPrizeBody",
                                      name: "winningNoPrizeBody",
                                      value: tenantVariables.winningNoPrizeBody || ""
                                  }}
                                  form={{
                                      setFieldValue: (field, value) => this.handleRichTextChange('winningNoPrizeBody', value)
                                  }}
                                  placeholder=""
                              />
                          </div>
                        <div className="form-group">
                          <label htmlFor="playingTextHeader">Holding Header Text:</label>
                          <input id="playingTextHeader" name="playingTextHeader" type="text" className="form-control" value={this.state.tenantVariables.playingTextHeader} onChange={this.handleChange} placeholder="No Game Up"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="playingTextBody">Holding Body Text:</label>
                            <RichTextMarkdown
                                field={{
                                    id: "playingTextBody",
                                    name: "playingTextBody",
                                    value: tenantVariables.playingTextBody || ""
                                }}
                                form={{
                                    setFieldValue: (field, value) => this.handleRichTextChange('playingTextBody', value)
                                }}
                                placeholder=""
                            />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
              <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
                  <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
              </Modal>
         </div>
        );
    }
}

export default SetUpBrandingVariables;
