import React, { Component } from 'react'
import '../../styles/css/SideMenu.css'
import '../../styles/css/AdminMain.css'
import '../../styles/css/TopBar.css'
import logoImage from '../../styles/images/sqwad_logo.png';

class TopBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const teamLogo = logoImage
        return (
          <div className="admin-topbar">
            <p className="topBarText">
              <img src={teamLogo} width="50px" height="auto" alt="Team" className="rounded-circle" style={{marginRight:'5px'}}/>
              SQWAD
            </p>
          </div>
        );
    }
}

export default TopBar;
